'use client';

import {
  ProductPostSkeleton,
  SliderSkeleton,
} from '../../../components/skeletons';

function HomeLoading() {
  return (
    <>
      <SliderSkeleton />
      <div className="flex flex-col gap-6 pt-4">
        {[1, 2, 3, 4].map(x => (
          <ProductPostSkeleton key={x} />
        ))}
      </div>
    </>
  );
}

export default HomeLoading;
